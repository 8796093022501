.google-map {
    padding-bottom: 60%;
    position: relative;
    /* border: dashed red; */
}

.google-map iframe {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}