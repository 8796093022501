/* .slides {
    float: left;
    height: 500px;
    object-fit: cover;
    object-position: 50% 50%;
} */
.slick-prev:before, .slick-next:before {
  color: black;
}


.slick-slide img {
    width: 15%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
  }


